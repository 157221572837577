import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {makeStyles, Typography} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import React from "react"
import theme from "../theme"

const useStyles = makeStyles({
    button: {
        color: "white",
        ...theme.mixins.toolbar,
        "&:hover": {
            textDecoration: "underline"
        },
    },
    socialText: {
        paddingTop: "8px",
        fontSize: 14,
        marginRight: "8px",
        [theme.breakpoints.only('md')]: {
            fontSize: 12
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
});

export default function (props) {
    const classes = useStyles();
    return (
        <Button
            size="large"
            variant="text"
            component="a"
            target="_blank"
            rel="noreferrer noopener"
            href={props.to}
            aria-label={props.label}
            className={classes.button}>
            <FontAwesomeIcon icon={props.icon} size="2x" style={{width: "48"}}/>
            <Typography className={classes.socialText}>
                {props.text}
            </Typography>
        </Button>
    )
}