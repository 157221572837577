import React from "react"
import AppBar from "@material-ui/core/AppBar"
import {Grid, makeStyles, Toolbar, Typography} from "@material-ui/core"
import theme from "../theme"
import Social from "./Social"
import SocialButton from "./SocialButton"

const useStyles = makeStyles({
    footer: {
        position: "absolute",
        bottom: 0,
        zIndex: 12001,
        backgroundColor: "#303F9F",
        [theme.breakpoints.up("md")]: {},
    },
    contact: {
        marginTop: "12px",
    },
});


export default function Footer() {
    const classes = useStyles();

    return (
        <AppBar position="relative" className={classes.footer}>
            <Toolbar>

                <Grid container>

                    <Grid item xs={4} sm={3} md={2}>
                        <Typography variant="h4" className={classes.contact}>
                            Connect |
                        </Typography>
                    </Grid>

                    {Social.map((s) => {
                        return <Grid item xs={2}>
                            <SocialButton icon={s.icon} text={s.text} to={s.link} label={s.label}/>
                        </Grid>
                    })
                    }

                </Grid>
            </Toolbar>
        </AppBar>
    )
}